import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

export const JoinHobbyList = [
  {
    name: '同好会',
    items: [
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-1.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-2.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-3.jpg'
      />,
    ],
  },
  {
    name: '旅游团建',
    items: [
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-4.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-5.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-6.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-7.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-8.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-9.jpg'
      />,
    ],
  },
  {
    name: '包场看电影',
    items: [
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-10.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-11.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-12.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-13.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-14.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-15.jpg'
      />,
    ],
    video:
      'https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/rayvision-join-hobby-2021-08-29.mp4',
  },
  {
    name: '兴趣比赛',
    items: [
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-16.png'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-17.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-18.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-19.jpg'
      />,
    ],
  },
  {
    name: '户外拓展',
    items: [
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-21.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-22.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-23.jpg'
      />,
    ],
    video:
      'https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/rayvision-join-sport-2021-08-02.mp4',
  },
  {
    name: '节日活动',
    items: [
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-24.jpg'
      />,
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../../images/join/join-hobby/join-hobby-25.jpg'
      />,
    ],
  },
]
