import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { transition } from '../../../style/theme'

const FadeInSwiperContainer = styled.div`
  position: relative;
  height: 100%;
  .fade-in-swiper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: ${transition('opacity')};
  }
  .actived {
    opacity: 1;
  }
  .no-actived {
    opacity: 0;
  }
`

const switchTime = 4000

const FadeInSwiper = React.memo(function ({ children, click, ...props }) {
  const [childrenDOM, setChilden] = useState(<React.Fragment />)
  const [currentIndex, setCurrentIndex] = useState(0)
  function setIndex() {
    if (children && children.length) {
      let targetIndex = currentIndex < children.length - 1 ? currentIndex + 1 : 0
      setCurrentIndex(targetIndex)
    }
  }
  useEffect(() => {
    setChilden(
      children.map((slide, index) => {
        return React.cloneElement(slide, {
          key: index,
          className:
            currentIndex === index ? 'fade-in-swiper actived' : 'fade-in-swiper no-actived',
        })
      }),
    )
    let timer = setTimeout(setIndex, switchTime)
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex])
  return (
    <FadeInSwiperContainer onClick={() => click(currentIndex)} {...props}>
      {childrenDOM}
    </FadeInSwiperContainer>
  )
})

export default FadeInSwiper
