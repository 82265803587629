import React from 'react'
import styled from 'styled-components'

const BackgroundVideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const BackgroundVideo = React.memo(function ({ link = '', ...props }) {
  return (
    <BackgroundVideoContainer>
      <video
        autoPlay
        muted
        loop
        playsInline
        webkit-playsinline='true'
        disablePictureInPicture
        {...props}
      >
        <source type='video/mp4' src={link} rel='nofollow' />
      </video>
    </BackgroundVideoContainer>
  )
})

export default BackgroundVideo
