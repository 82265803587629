import styled from 'styled-components'
import {
  color,
  fontSize,
  Media,
  spacing,
  SwiperNavigationButton,
  transition,
} from '../../../style/theme'

export const JoinHobbyBannerContainer = styled.div`
  width: 100%;
  .hobby-auto-swiper {
    height: 500px;
    ${SwiperNavigationButton};
    .swiper-pagination {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .all-pagination {
      width: ${spacing.base};
      height: ${spacing.base};
      margin: 0 ${spacing.small};
      background: #bec3c5;
      border-radius: ${spacing.small};
      cursor: pointer;
      transition: ${transition(['background', 'width'])};
    }
    .actived-pagination {
      width: 60px;
      background: ${color.primaryDark};
    }
  }
  ${Media.phone`
    .hobby-auto-swiper {
      height: 300px;
    }
    .all-pagination {
      width: 15px;
      height: 15px;
    }
  `}
`
export const JoinHobbyBannerContent = styled.div`
  height: 420px;
  width: 100%;
  img {
    border-radius: 10px;
  }
  ${Media.phone`
    height: 260px;
  `};
`
export const JoinHobbyImageContent = styled.div`
  position: relative;
  height: 320px;
  width: 100%;
  z-index: 0;
  img {
    border-radius: 10px 10px 0 0;
  }
  ${Media.phone`
    height: 200px;
  `};
`

export const PlayIconContainer = styled.div`
  position: absolute;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  z-index: 2;
  svg {
    fill: white;
    width: 80px;
    transition: ${transition('fill')};
    cursor: pointer;
  }
  :hover {
    svg {
      fill: ${color.primary};
    }
  }
`

export const JoinHobbyBannerVerticalContent = styled.div`
  position: relative;
  height: 320px;
  z-index: 0;
  cursor: zoom-in;
  ${Media.phone`
    height: 200px;
  `};
`

export const JoinHobbyBottomInfo = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
  background-color: ${color.textDark};
  font-weight: bold;
  font-size: 27px;
  ${Media.phone`
    height: 60px;
    font-size: ${fontSize.base};
  `};
`

export const JoinModalContent = styled.div`
  position: relative;
  max-width: 50%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .background-image {
    position: relative;
    width: 100%;
  }
  video {
    width: 100%;
  }
  ${Media.phone`
    max-width: 100%;
    max-height: 100%;
  `}
`
