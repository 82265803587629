import * as React from 'react'

import SwiperCore, { Navigation, Autoplay, EffectCoverflow } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { StaticImage } from 'gatsby-plugin-image'

import { JoinOfficeBannerContainer, JoinOfficeBannerContent } from './join-office-banner.atom'

SwiperCore.use([EffectCoverflow, Navigation, Autoplay])

const OfficeImageList = [
  <StaticImage
    className='background-image'
    layout='fullWidth'
    alt=''
    src='../../../images/join/join-office/join-office-1.png'
  />,
  <StaticImage
    className='background-image'
    layout='fullWidth'
    alt=''
    src='../../../images/join/join-office/join-office-2.png'
  />,
  <StaticImage
    className='background-image'
    layout='fullWidth'
    alt=''
    src='../../../images/join/join-office/join-office-3.png'
  />,
  <StaticImage
    className='background-image'
    layout='fullWidth'
    alt=''
    src='../../../images/join/join-office/join-office-4.png'
  />,
  <StaticImage
    className='background-image'
    layout='fullWidth'
    alt=''
    src='../../../images/join/join-office/join-office-5.png'
  />,
  <StaticImage
    className='background-image'
    layout='fullWidth'
    alt=''
    src='../../../images/join/join-office/join-office-6.png'
  />,
]

const JoinOfficeBanner = React.memo(() => {
  return (
    <JoinOfficeBannerContainer>
      {/* https://github.com/nolimits4web/Swiper/issues/2276#issuecomment-339918354 */}
      <Swiper
        effect='coverflow'
        slidesPerView={1.5}
        navigation
        loop
        centeredSlides
        observer
        coverflowEffect={{
          rotate: 0,
          stretch: 413,
          depth: 200,
          modifier: 1,
          slideShadows: true,
        }}
      >
        {OfficeImageList.map((image, key) => (
          <SwiperSlide key={key}>
            <JoinOfficeBannerContent>{image}</JoinOfficeBannerContent>
          </SwiperSlide>
        ))}
      </Swiper>
    </JoinOfficeBannerContainer>
  )
})

export default JoinOfficeBanner
