/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react'

import { Layout } from '../components/layout'
import Seo from '../components/seo'
import JoinFooter from '../components/join-footer'
import { StaticImage } from 'gatsby-plugin-image'
import { JoinHeaderBanner, JoinHobbyBanner, JoinOfficeBanner } from '../components/join'
import {
  JoinGrownContainer,
  JoinGrownLineContainer,
  JoinGrownVideoContainer,
  JoinKnowBottomWave,
  JoinKnowContainer,
  JoinKnowContent,
  JoinSectionTitle,
  JoinSideContainer,
  JoinSideTitle,
  JoinSwiperSideContainer,
  JoinSectionContainer,
  JoinTacitContent,
  JoinUsContainer,
  JoinTacitContainer,
  JoinKnowCardContainer,
} from '../style/pages/join-us.atom'

import WaveImage from '../images/join/wave-background.png'
import JoinUsTrainPosterImage from '../images/join/join-us-train.jpg'
import { JoinUsKownList, JoinUsTacitList } from '../constant/join-us-page-data'
import { JoinGrowLine } from '../components/icons'

const JoinUsPage = () => {
  return (
    <Layout>
      <Seo
        title='快来加入RAYVISION伐木累吧'
        description='瑞云科技社会招聘-深圳市瑞云科技股份有限公司是一家专注为视觉行业提供垂直云计算服务的公司,随着5G的普及,瑞云科技作为“新型基础设施”,致力为视觉行业提供一系列IaaS、PaaS及SaaS云服务.加入我们,渲染无限未来'
        keywords='瑞云渲染招聘，瑞云科技招聘，Rayvision 招聘'
      />
      <JoinUsContainer>
        <JoinHeaderBanner />
        <JoinKnowContainer>
          <JoinSectionTitle>认识瑞云</JoinSectionTitle>
          <JoinKnowCardContainer>
            {JoinUsKownList.map((info) => (
              <JoinKnowContent key={info.title}>
                {info.icon}
                <h5>{info.title}</h5>
                <p>{info.content}</p>
              </JoinKnowContent>
            ))}
          </JoinKnowCardContainer>
          <JoinKnowBottomWave src={WaveImage} alt='wave' />
        </JoinKnowContainer>
        <div>
          <JoinSectionTitle>吸引力的定律</JoinSectionTitle>
          <JoinSectionContainer>
            <JoinSideContainer>
              <JoinSideTitle marginTop='70px'>
                <p>磁场相惜</p>
                <h3>我们有特别的默契</h3>
              </JoinSideTitle>
            </JoinSideContainer>
            <JoinTacitContainer>
              {JoinUsTacitList.map((info) => (
                <JoinTacitContent key={info.title}>
                  <img src={info.img} alt={info.title} />
                  <h5>{info.title}</h5>
                  <p>{info.content}</p>
                </JoinTacitContent>
              ))}
            </JoinTacitContainer>
          </JoinSectionContainer>
        </div>
        <JoinGrownContainer>
          <JoinGrownVideoContainer>
            <JoinSideTitle>
              <p>未来可期</p>
              <h3>我们关注你的成长</h3>
            </JoinSideTitle>
            <video controls poster={JoinUsTrainPosterImage}>
              <source
                type='video/mp4'
                src='https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/rayvision-join-train-2021-08-05.mp4'
                rel='nofollow'
              />
            </video>
          </JoinGrownVideoContainer>
          <JoinGrownLineContainer>
            <h3>人才发展通道</h3>
            <JoinGrowLine />
          </JoinGrownLineContainer>
          <StaticImage
            className='background-image'
            layout='fullWidth'
            alt=''
            src='../images/join/join-grown-bg.png'
          />
        </JoinGrownContainer>
        <div>
          <JoinSectionContainer>
            <JoinSideContainer>
              <JoinSideTitle marginTop='70px'>
                <p>气场相合</p>
                <h3>我们与你感同身受</h3>
              </JoinSideTitle>
            </JoinSideContainer>
            <JoinSwiperSideContainer>
              <JoinOfficeBanner />
            </JoinSwiperSideContainer>
          </JoinSectionContainer>
        </div>
        <div>
          <JoinSectionContainer>
            <JoinSideContainer>
              <JoinSideTitle marginTop='30px'>
                <p>趣味相投</p>
                <h3>我们有共同的爱好</h3>
              </JoinSideTitle>
            </JoinSideContainer>
            <JoinSwiperSideContainer>
              <JoinHobbyBanner />
            </JoinSwiperSideContainer>
          </JoinSectionContainer>
        </div>
        <JoinFooter />
      </JoinUsContainer>
    </Layout>
  )
}

export default JoinUsPage
