import styled, { css } from 'styled-components'
import { color, fontSize, Media, spacing, transition } from '../../style/theme'

export const JoinFooterOutterContainer = styled.div`
  position: relative;
  color: white;
  height: 520px;
  z-index: 0;
  ${Media.phone`
    height: 435px;
  `}
`

export const JoinFooterContainer = styled.div`
  max-width: 1554px;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  ${Media.phone`
    flex-wrap: wrap;
    align-content: center;
  `}
`

export const QRCodeContainer = styled.div`
  width: 550px;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 150px;
  }
  ${Media.phone`
    width: 315px;
    margin: 53px auto 55px;
    img {
      width: 90px;
    }
  `}
`

export const QRCodeContent = styled.div`
  margin-left: ${spacing.large};
  line-height: 45px;
  font-size: ${fontSize.medium};
  a {
    font-weight: bold;
    font-size: ${fontSize.large};
    transition: ${transition('color')};
    :hover {
      color: ${color.primary};
    }
  }
  a :hover {
    color: ${color.primaryLight};
  }
  ${Media.phone`
    margin-left: ${spacing.base};
    font-size: ${fontSize.mini};
    line-height: 30px;
    a {
      font-size: ${fontSize.base};
    }
  `}
`

export const RecruitmentContainer = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  ${Media.phone`
    width: 310px;
    margin: 0 auto 156px;
  `}
`

export const RecruitmentContent = styled.div`
  width: 196px;
  height: 196px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-width: 2px;
  border-style: solid;
  border-color: white;
  background-color: white;
  border-radius: 18px;
  svg {
    height: 60px;
    path {
      /* stroke: ${color.textDark}; */
      fill: ${color.textDark};
    }
  }
  a {
    display: inline-block;
    width: 140px;
    line-height: ${fontSize.plus};
    text-align: center;
    border-radius: 18px;
    background-color: white;
    color: ${color.textDark};
    border: 0;
    transition: ${transition(['background-color', 'box-shadow', 'color'])};
    :hover {
      color: white;
      background-color: ${color.primaryDark};
      box-shadow: 0px 10px 16px 4px rgba(2, 166, 188, 0.35);
    }
  }
  ${(p) =>
    p.isStudent &&
    css`
      background-color: transparent;
      svg {
        path {
          fill: white;
        }
      }
    `}
  & + & {
    margin-left: 60px;
  }
  ${Media.phone`
    width: 128px;
    height: 128px;
    border-width: 1px;
    border-radius: 9px;
    svg {
      height:35px;
    }
    a {
      width: 90px;
      line-height: ${fontSize.large};
      font-size: ${fontSize.mini};
      border-radius: 9px;
    }
  `}
  :
`
