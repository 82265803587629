/* eslint-disable jsx-a11y/media-has-caption */
import * as React from 'react'
import { useState } from 'react'

import SwiperCore, { EffectFade, Pagination } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'
import { PlayIcon } from '../../icons'
import Modal from '../../modal/modal'
import FadeInSwiper from '../fade-in-swiper'

import {
  JoinHobbyBannerContainer,
  JoinHobbyBannerContent,
  JoinHobbyBannerVerticalContent,
  JoinHobbyBottomInfo,
  JoinHobbyImageContent,
  JoinModalContent,
  PlayIconContainer,
} from './join-hobby-banner.atom'
import { JoinHobbyList } from './slider-list'

SwiperCore.use([Pagination, EffectFade])

const JoinHobbyBanner = React.memo(() => {
  const [modalElement, setModalElement] = useState(null)
  function clearModalElement() {
    setModalElement(null)
  }
  function setModalVideo(link) {
    const videoEl = (
      <video controls>
        <source type='video/mp4' src={link} rel='nofollow' />
      </video>
    )

    setModalElement(videoEl)
  }
  return (
    <JoinHobbyBannerContainer>
      {/* https://github.com/nolimits4web/Swiper/issues/2276#issuecomment-339918354 */}
      <Swiper
        className='hobby-auto-swiper'
        slidesPerView={1}
        slidesPerGroup={1}
        loop
        observer
        spaceBetween={30}
        pagination={{
          clickable: true,
          bulletActiveClass: 'actived-pagination',
          bulletClass: 'all-pagination',
        }}
        breakpoints={{
          812: {
            slidesPerView: 2,
            slidesPerGroup: 2,
          },
        }}
      >
        {JoinHobbyList.map((info, key) => (
          <SwiperSlide key={key}>
            <JoinHobbyBannerContent>
              <JoinHobbyImageContent>
                <FadeInSwiper
                  click={(currentIndex) => {
                    setModalElement(info.items[currentIndex])
                  }}
                >
                  {info.items.map((image, index) => (
                    <JoinHobbyBannerVerticalContent key={index}>
                      {image}
                    </JoinHobbyBannerVerticalContent>
                  ))}
                </FadeInSwiper>
                {info.video && (
                  <PlayIconContainer
                    onClick={() => {
                      setModalVideo(info.video)
                    }}
                  >
                    <PlayIcon />
                  </PlayIconContainer>
                )}
              </JoinHobbyImageContent>
              <JoinHobbyBottomInfo>{info.name}</JoinHobbyBottomInfo>
            </JoinHobbyBannerContent>
          </SwiperSlide>
        ))}
      </Swiper>
      <Modal show={!!modalElement} onClose={clearModalElement}>
        <JoinModalContent>{modalElement}</JoinModalContent>
      </Modal>
    </JoinHobbyBannerContainer>
  )
})

export default JoinHobbyBanner
