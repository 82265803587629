import styled from 'styled-components'
import { color, fontSize, Media, spacing, transition } from '../theme'

export const JoinUsContainer = styled.div`
  background: #101c27;
  color: white;
  ${Media.phone`
    padding-bottom: 80px;
  `}
`

export const JoinSectionTitle = styled.h2`
  padding: 90px 0;
  font-size: ${fontSize.giant};
  font-weight: bold;
  color: #f3f6f7;
  line-height: 1;
  text-align: center;
  ${Media.phone`
    padding: ${spacing.plus} 0;
    font-size: 30px;
  `}
`

export const JoinKnowContainer = styled.div`
  position: relative;
  padding: 0 0 120px 30px;
  color: white;
  z-index: 1;
  background-color: #222;
  overflow: hidden;
  ${Media.phone`
    padding: 0 ${spacing.small} 50px;
  `}
`

export const JoinKnowCardContainer = styled.div`
  width: 1750px;
  max-width: 100%;
  margin: auto;
  ${Media.phone`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`

export const JoinKnowContent = styled.div`
  display: inline-block;
  padding-top: ${spacing.plus};
  margin-right: ${spacing.large};
  width: 320px;
  height: 260px;
  text-align: center;
  border-radius: 10px;
  background: transparent;
  vertical-align: top;
  svg {
    height: 60px;
    path {
      transition: ${transition('fill')};
      fill: ${color.primaryDark};
    }
  }
  h5 {
    margin: ${spacing.plus} 0 ${spacing.base};
    font-size: ${fontSize.large};
    font-weight: bold;
    line-height: 1;
  }
  p {
    margin: 0;
    font-size: ${fontSize.base};
  }
  :hover {
    background: linear-gradient(0deg, ${color.primaryLight} 0%, ${color.primaryDark} 100%);
    box-shadow: 0px 10px 16px 4px rgba(2, 166, 188, 0.35);
    svg path {
      fill: white;
    }
  }
  ${Media.phone`
    padding-top: ${spacing.base};
    margin-right: 0;
    width: 160px;
    height: 160px;
    svg {
      height: 38px;
    }
    h5 {
      margin: 16px 0 ${spacing.small};
      font-size: ${fontSize.base};
    }
    p {
      font-size: ${fontSize.tiny};
    }
  `}
`

export const JoinKnowBottomWave = styled.img`
  height: 110px;
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  z-index: -1;
  ${Media.phone`
    height: 50px;
  `}
`

export const JoinSectionContainer = styled.div`
  display: flex;
  padding: ${spacing.base} 0 ${spacing.small};
  @media (max-width: 1820px) {
    flex-direction: column;
    padding: ${spacing.plus} ${spacing.small} ${spacing.base};
  }
`

export const JoinSideContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 720px;
  padding-left: 100px;
  @media (max-width: 1820px) {
    width: 100%;
    padding-left: 0;
  }
`

export const JoinSwiperSideContainer = styled.div`
  max-width: 1092px;
  width: 100%;
  @media (max-width: 1820px) {
    align-self: center;
    padding-top: ${spacing.super};
  }
`

export const JoinSideTitle = styled.div`
  p {
    margin: 0 0 ${spacing.base} 0;
    font-size: ${fontSize.large};
  }
  h3 {
    font-size: ${fontSize.super};
    font-weight: bold;
    line-height: 1;
  }
  @media (min-width: 1820px) {
    margin-top: ${(p) => p.marginTop || 0};
  }
  ${Media.phone`
    p {
      margin: 0 0 ${spacing.small} 0;
      font-size: ${fontSize.mini};
    }
    h3 {
      font-size: ${fontSize.large};
    }
  `}
`

export const JoinTacitContainer = styled.div`
  @media (max-width: 1820px) {
    padding-top: ${spacing.super};
    padding-left: 100px;
  }
  ${Media.phone`
    padding-top: ${spacing.base};
    padding-left: 0;
  `}
`

export const JoinTacitContent = styled.div`
  display: inline-block;
  position: relative;
  width: 520px;
  height: 300px;
  margin: 0 ${spacing.large} ${spacing.large} 0;
  z-index: 1;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  h5 {
    position: absolute;
    left: ${spacing.large};
    bottom: 60px;
    font-size: ${fontSize.plus};
    font-weight: bold;
    line-height: 1;
  }
  p {
    position: absolute;
    left: ${spacing.large};
    bottom: ${spacing.large};
    margin: 0;
    font-size: ${fontSize.base};
  }
  ${Media.phone`
    display: block;
    margin: 0 auto ${spacing.small};
    width: 325px;
    height: 188px;
    h5 {
      left: ${spacing.base};
      bottom: ${spacing.large};
      font-size: ${fontSize.large};
    }
    p {
      left: ${spacing.base};
      bottom: ${spacing.small};
      font-size: ${fontSize.mini};
    }
  `}
`

export const JoinGrownContainer = styled.div`
  display: flex;
  position: relative;
  padding: 90px 100px 125px;
  z-index: 0;
  @media (max-width: 1820px) {
    flex-direction: column;
  }
  ${Media.phone`
    padding: 50px ${spacing.small};
  `}
`

export const JoinGrownVideoContainer = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  width: 810px;
  video {
    margin-top: ${spacing.super};
    width: 720px;
  }
  ${Media.phone`
    width: 100%;
    video {
      margin-top: ${spacing.plus};
      width: 100%;
    }
  `}
`

export const JoinGrownLineContainer = styled.div`
  h3 {
    margin: 56px 0 ${spacing.large};
    font-size: ${fontSize.super};
    font-weight: bold;
    line-height: 1;
  }
  svg {
    width: 597px;
    max-width: 100%;
  }
  ${Media.phone`
    h3 {
      margin: ${spacing.plus} 0 ${spacing.large};
      font-size: ${fontSize.large};
    }
  `}
`
