import React from 'react'
import {
  JoinUsKown1,
  JoinUsKown2,
  JoinUsKown3,
  JoinUsKown4,
  JoinUsKown5,
} from '../components/icons'

import TacitImage1 from '../images/join/join-us-tacit-1.png'
import TacitImage2 from '../images/join/join-us-tacit-2.png'
import TacitImage3 from '../images/join/join-us-tacit-3.png'
import TacitImage4 from '../images/join/join-us-tacit-4.png'

export const JoinUsKownList = [
  {
    icon: <JoinUsKown1 />,
    title: '客户第一',
    content: '了解客户想要的，帮助客户成功',
  },
  {
    icon: <JoinUsKown2 />,
    title: '同心协力',
    content: '同一个理想，同一个目标',
  },
  {
    icon: <JoinUsKown3 />,
    title: '言出必行',
    content: '言行一致，说到做到',
  },
  {
    icon: <JoinUsKown4 />,
    title: '主动学习',
    content: '自我更新，与时俱进',
  },
  {
    icon: <JoinUsKown5 />,
    title: '拥抱变化',
    content: '预见变化，引领变化',
  },
]

export const JoinUsTacitList = [
  { img: TacitImage1, title: '认同', content: '心有所依，携手共进' },
  { img: TacitImage2, title: '责任', content: '格尽职守，责无旁贷' },
  { img: TacitImage3, title: '干劲', content: '斗志昂扬，孜孜不倦' },
  { img: TacitImage4, title: '成长', content: '自强不息，学无止境' },
]
