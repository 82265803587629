import styled from 'styled-components'
import { Media, SwiperNavigationButton } from '../../../style/theme'

export const JoinOfficeBannerContainer = styled.div`
  height: 400px;
  width: 100%;
  position: relative;
  margin-bottom: 130px;
  ${SwiperNavigationButton};
  ${Media.phone`
    height: 160px;
    margin-bottom: 0;
  `}
`
export const JoinOfficeBannerContent = styled.div`
  height: 400px;
  width: 100%;
  z-index: 0;
  img {
    border-radius: 10px;
  }
  ${Media.phone`
    height: 160px;
  `}
`
