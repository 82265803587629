import { Link } from 'gatsby-plugin-react-intl'
import styled from 'styled-components'
import {
  color,
  fontSize,
  hideAtMobile,
  Media,
  spacing,
  SwiperNavigationButton,
  transition,
} from '../../../style/theme'

export const JoinBannerContainer = styled.div`
  height: 680px;
  width: 100%;
  position: relative;
  ${SwiperNavigationButton}
  .swiper-button-prev,
  .swiper-button-next,
  .hidden-on-mobile {
    ${hideAtMobile};
  }
  ${Media.phone`
    height: 340px;
  `}
`
export const JoinBannerContent = styled.div`
  height: 680px;
  width: 100%;
  z-index: 0;
  ${Media.phone`
    height: 340px;
  `}
`

export const JoinBannerSection = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 200px auto 0;
  width: 615px;
  text-align: center;
  color: white;
  z-index: 2;
  ${Media.phone`
    margin: 100px auto 0;
    width: 310px;
  `}
`

export const JoinBannerTitle = styled.h1`
  margin: ${spacing.base} auto;
  font-family: Source Han Sans CN, Source Han Sans SC, Lato;
  font-size: ${fontSize.giant};
  font-weight: bold;
  color: #f3f6f7;
  line-height: 1;
  span {
    margin: 0 15px;
  }
  ${Media.phone`
    margin: ${spacing.small} auto;
    font-size: 30px;
  `}
`

export const JoinBannerJobBar = styled.div`
  margin: ${spacing.base} auto 60px;
  height: ${spacing.plus};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(51 51 51 / 60%);
  font-size: ${fontSize.small};
  span {
    margin: 0 5px;
  }
  a {
    margin: 0 5px;
    transition: ${transition('color')};
    :hover {
      color: ${color.primaryLight};
    }
  }
  ${Media.phone`
    margin: ${spacing.base} auto ${spacing.large};
    font-size: ${fontSize.tiny};
    a,span {
      margin: 0 2px;
    }
  `}
`

export const JoinBannerBtnGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`

export const JoinBannerBtn = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  border-radius: 25px;
  height: 50px;
  background-color: rgba(51 51 51 / 60%);
  font-size: ${fontSize.base};
  transition: ${transition(['background-color', 'box-shadow'])};
  svg {
    height: 30px;
    margin-right: ${spacing.base};
  }
  :hover {
    color: white;
    background-color: ${color.primaryDark};
    box-shadow: 0px 10px 16px 4px rgba(2, 166, 188, 0.35);
  }
  ${Media.phone`
    width: 150px;
    height: 36px;
    font-size: ${fontSize.small};
    svg {
      height: 22px;
    }
  `}
`
