import * as React from 'react'
import { Link } from 'gatsby-plugin-react-intl'

import SwiperCore, { Navigation, Autoplay } from 'swiper/core'
import { Swiper, SwiperSlide } from 'swiper/react'

import {
  JoinBannerBtn,
  JoinBannerBtnGroup,
  JoinBannerContainer,
  JoinBannerContent,
  JoinBannerJobBar,
  JoinBannerSection,
  JoinBannerTitle,
} from './join-header-banner.atom'
import BackgroundVideo from '../../background-video'
import { JoinUsOffice, JoinUsStudent } from '../../icons'
import JoinBackground from '../../../images/join/join-banner.jpg'

SwiperCore.use([Navigation, Autoplay])

const JobList = [
  { name: '研发类', id: 'research' },
  { name: '技术类', id: 'technology' },
  { name: '业务类', id: 'business' },
  // { name: '职能类', id: 'position' },
  // { name: '设计类', id: 'design' },
]

const JoinHeaderBanner = React.memo(() => {
  return (
    <JoinBannerContainer>
      <JoinBannerSection>
        <JoinBannerTitle>
          <span>J O I N</span> <span>U S</span>
        </JoinBannerTitle>
        <JoinBannerTitle>加入我们 渲染无限未来</JoinBannerTitle>
        <JoinBannerJobBar>
          <span>热搜岗位:</span>
          {JobList.map((job) => (
            <Link key={job.id} to={`/recruitment.html#${job.id}`}>
              {job.name}
            </Link>
          ))}
        </JoinBannerJobBar>
        <JoinBannerBtnGroup>
          <JoinBannerBtn to='/recruitment.html'>
            <JoinUsOffice />
            社会招聘
          </JoinBannerBtn>
          <JoinBannerBtn to='/campus-recruitment.html'>
            <JoinUsStudent />
            校园招聘
          </JoinBannerBtn>
        </JoinBannerBtnGroup>
      </JoinBannerSection>
      {/* https://github.com/nolimits4web/Swiper/issues/2276#issuecomment-339918354 */}
      <Swiper navigation loop observer>
        <SwiperSlide className='hidden-on-mobile'>
          <JoinBannerContent>
            <BackgroundVideo
              link='https://renderbus-assets.oss-cn-hangzhou.aliyuncs.com/video/rayvision-join-us-2021-07-29.mp4'
              poster={JoinBackground}
            />
          </JoinBannerContent>
        </SwiperSlide>
        <SwiperSlide>
          <JoinBannerContent>
            <img className='background-image' layout='fullWidth' alt='' src={JoinBackground} />
          </JoinBannerContent>
        </SwiperSlide>
      </Swiper>
    </JoinBannerContainer>
  )
})

export default JoinHeaderBanner
