import React from 'react'
import { JoinUsOffice, JoinUsStudent } from '../icons'
import {
  JoinFooterOutterContainer,
  JoinFooterContainer,
  QRCodeContainer,
  QRCodeContent,
  RecruitmentContainer,
  RecruitmentContent,
} from './join-footer.atom'

import QRCodeImg from '../../images/join/wechat-qrcode.png'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby-plugin-react-intl'
import { useLocation } from '@reach/router'

const RecruitmentList = [
  {
    link: '/recruitment.html',
    name: '社会招聘',
    icon: <JoinUsOffice />,
  },
  {
    link: '/campus-recruitment.html',
    name: '校园招聘',
    icon: <JoinUsStudent />,
    isStudent: true,
  },
]

export default React.memo(function JoinFooter() {
  const location = useLocation()
  let recruitmentList = RecruitmentList.filter((info) => info.link !== location.pathname)
  return (
    <JoinFooterOutterContainer>
      <JoinFooterContainer>
        <QRCodeContainer>
          <img src={QRCodeImg} alt='qr-code' />
          <QRCodeContent>
            <p>关注招聘微信号接收更多招聘信息</p>
            <p>
              <a href='mailto: hr@rayvision.com' title='hr@rayvision.com'>
                投递简历
              </a>
            </p>
            <p>
              HR邮箱:
              <a href='mailto: hr@rayvision.com' title='hr@rayvision.com'>
                hr@rayvision.com
              </a>
            </p>
          </QRCodeContent>
        </QRCodeContainer>
        <RecruitmentContainer>
          {recruitmentList.map((info) => (
            <RecruitmentContent isStudent={info.isStudent} key={info.link}>
              {info.icon}
              <Link to={info.link}>{info.name}</Link>
            </RecruitmentContent>
          ))}
        </RecruitmentContainer>
      </JoinFooterContainer>
      <StaticImage
        className='background-image'
        layout='fullWidth'
        alt=''
        src='../../images/join/join-footer.png'
      />
    </JoinFooterOutterContainer>
  )
})
